<template>
  <div>
    <div class="white-content question-page" style="padding-top: 1px;">
      <div id="styledElement">
        <b-container id="resizeElement" style="padding: 0">
          <b-row>
            <b-col>
              <div class="quiz-box">
                <div class="rounded-circle hide-desktop">{{ currentQuestionIdx + 1 }} / {{ questions.length }}</div>
                  <div class="quiz-content">
                    <div class="quiz-image">
                      <img v-if="currentQuestion.question_image.length" :src="currentQuestion.question_image">
                    </div>
                    <div class="txt">
                      <div class="title-holder">
                        <h1 class="quiz-title"><span class="subtitle">QUESTION #{{ currentQuestionIdx + 1 }}:</span>{{ currentQuestion.title }} </h1>
                      </div>
                      <p class="question">{{ currentQuestion.question }}</p>
                    </div>
                    <div class="q-body">
                        <ul class="quiz-answer-list">
                          <li :disabled="answered" @click="!answered && checkAnswer(key)" v-for="(answer, key) in currentQuestion.answers" :key="key" :class="{ active: key === currentQuestionAnswer && !answered }">
                            <b-form-radio :disabled="answered" :value="key">
                              <!-- <span :class="{ correct: (answered && currentQuestionAnswer === key && answer.correct), incorrect: (answered && currentQuestionAnswer === key && !answer.correct) }">{{ answer.answer }}</span> -->
                              <span :class="{ correct: (answered && answer.correct), incorrect: (answered && currentQuestionAnswer === key && !answer.correct) }">{{ answer.answer }}</span>
                            </b-form-radio>
                          </li>
                        </ul>
                    </div>
                    <div class="clearfix"></div>
                  </div>
                <div class="blue-content" :class="{ blue: answered }">
                  <div class="quiz-question-info" v-if="answered" v-html="currentQuestion.info">
                  </div>
                  <div class="quiz-bottom">
                    <div class="bottom-left">
                      <div class="rounded-circle hide-mob">{{ currentQuestionIdx + 1 }} / {{ questions.length }}</div>
                      <b-btn v-if="answered && (this.currentQuestionIdx < this.questions.length - 1)" @click="goToNextQuestion" class="btn">NEXT QUESTION</b-btn>
                      <div v-if="answered">
                        <b-btn class="btn" v-if="!(this.currentQuestionIdx < this.questions.length - 1)" @click="endGame">
                          Get Score
                        </b-btn>
                      </div>
                    </div>
                    <div class="bottom-right">
                      <img v-if="answered" src="../assets/img/Logosb.png">
                      <img v-else src="../assets/img/Logos.png">
                    </div>
                    <div class="clearfix"></div>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import questions from '../assets/quiz.json'

export default {
  name: 'Quiz',
  data () {
    return {
      currentQuestionIdx: 0,
      currentQuestionAnswer: null,
      answered: false,
      correctAnswers: [],
      wrongAnswers: [],
      questions
    }
  },
  computed: {
    ...mapGetters(['score']),
    currentQuestion () {
      return this.questions[this.currentQuestionIdx]
    },
    totalQuestions () {
      return this.questions.length
    }
  },
  methods: {
    ...mapMutations(['setAnswer', 'clearAnswers']),
    checkAnswer (answer) {
      this.answered = true
      this.setAnswer(answer)
      this.currentQuestionAnswer = answer
      if (this.currentQuestion.answers[this.currentQuestionAnswer].correct) {
        this.correctAnswers.push(this.currentQuestionIdx)
        this.wrongAnswers = this.wrongAnswers.filter(a => a !== this.currentQuestionIdx)
      } else {
        if (!this.wrongAnswers.includes(this.currentQuestionIdx)) {
          this.wrongAnswers.push(this.currentQuestionIdx)
        }
      }
    },
    goToNextQuestion () {
      if (this.currentQuestionIdx <= this.questions.length - 1) {
        const remaining = [...Array(this.totalQuestions).keys()].map((i) => i).filter(q => !this.correctAnswers.includes(q))
        if (remaining.length) {
          const q = remaining.filter(r => r > this.currentQuestionIdx)[0]
          if (q) {
            this.currentQuestionIdx = q
            this.currentQuestionAnswer = null
            this.answered = false
          }
        } else {
          this.currentQuestionIdx = this.questions.length - 1
          this.answered = true
        }
        require(this.questions[this.currentQuestionIdx].question_image)
      }
    },
    setCurrentQuestion (q) {
      this.currentQuestionAnswer = null
      this.answered = false
      this.currentQuestionIdx = q
    },
    endGame () {
      this.$router.push({ name: 'Score' })
    }
  },
  mounted () {
    this.clearAnswers()
  }
}
</script>

<style scoped>
.quiz-answer-list, .quiz-score {
  list-style-type: none;
}

.correct {
  color: #00EE00;
}

.incorrect {
  color: #66A3BD;
}

.quiz-score li {
  float: left;
  margin-left: 10px;
}

.quiz-score {
  display: flex;
  justify-content: center;
  padding-left: 0;
  font-size: 25px;
}

.quiz-answer-list .active span {
  background-color: #66A3BD;
  color: #fff;
}
.quiz-answer-list li:not([disabled]):hover span {
  background-color: #C0DA47;
  cursor: pointer;
  transform: scale(1.03);
}
</style>
